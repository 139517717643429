<template>
  <router-link :to="localizePath(route)">
    <div class="back-button">
      <atomic-icon id="back" />
      <div>{{ title ?? 'back' }}</div>
    </div>
  </router-link>
</template>

<script setup lang="ts">
  defineProps<{
    route?: string;
    title?: string;
  }>();
  const { localizePath } = useProjectMethods();
</script>

<style src="~/assets/styles/components/button/back.scss" lang="scss" />
